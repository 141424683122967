import React,{Component} from 'react';
import plugin from 'plugin';
import { bindLifecycle } from 'utils/bindLifecycle';
import {LangConsumer} from 'utils/LangProvider';
import {isZeroArray} from '../../../../common';
import _ from 'lodash';

class FocusExpertPlugin extends React.Component {
    /**用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = 'FocusExpertPlugin';
        this.state = {
            rule: {}, // 需要返回的规则参数
            containerID: 0, // 当前容器ID
            btnStatus: [
                true // 添加检测区
            ], // 各个按钮的状态,true为点击，false为不点击
            shapeType: 'Polygon',
            curIndex: -1,//当前选中的编号
            regionIds: []//存放场景id
        };
    }
    componentDidMount () {
        this.addPluginEvent();
    }
    componentDidActivate () {
        this.addPluginEvent();
    }
    componentWillUnactivate () {
        let {channel} = this.props;
        //console.log('componentWillUnactivate');
        this.leave(channel);
    }
    componentWillUnmount () {
        let {channel} = this.props;
        // console.log('componentWillUnmount');
        this.leave(channel);
    }
    /**
     * 离开页面触发
     * 1. 清空规则；2.销毁容器；3.取消监听；4.隐藏插件；5.停止拉流
     */
    leave = (channel) => {
        let {containerID} = this.state;
        // 清空规则，防止在预览或者其他页面中重新绘制
        plugin.DeleteAllVideoAnalyseShape(containerID);
        // 销毁容器
        plugin.ReleaseVideoAnalyseContainer(containerID);
        this.setState({containerID: 0, regionIds: []});
        plugin.addEvent('VideoAnalyzeConfig', null);
    }
    addPluginEvent = () => {
        plugin.addEvent('VideoAnalyzeConfig', (shapeID, data, curState, preState, eventType, eventName, markedName, shapeType) => {
            let {containerID, rule, regionIds, curIndex} = this.state;
            let {$t, curScene} = this.props;
            if (curState === 3) {
                switch (eventName) {
                case 'PolygonEvent':
                    rule.Points = data;
                    regionIds[curIndex] = shapeID;
                    plugin.SetVideoAnalyseContainerTip(containerID, $t('com.DrawingIsCompletedTip'));
                    break;
                }
                this.setState({rule, regionIds});
            }
        });
    }
    drawScene = async (cur = this.props.curScene, bool) => {
        let {containerID, rule, regionIds, shapeType, curIndex} = this.state;
        curIndex = cur.Index;
        rule = cur.Rules;
        // 如果已经有图形窗口，则不需要重新创建
        if (containerID === 0) {
            if (plugin.isInStalled) {
                await plugin.CreateVideoAnalyseContainer().then(id => {
                    plugin.EnableVideoAnalyseContainer(id, true);
                    containerID = id;
                    this.setState({containerID});
                });
            }
        }
        await plugin.DeleteAllVideoAnalyseShape(containerID).then(() => {
            regionIds = [];
            if (rule.Points && rule.Points.length !== 0 && !isZeroArray(rule.Points)) {
                plugin.CreateMainVideoAnalyseShape(containerID, 'PolygonEvent', shapeType, '', JSON.stringify({Polygon: rule.Points}), '').then(shapeId => {
                    regionIds[curIndex] = shapeId;
                    plugin.EnableVideoAnalyseShape(containerID, shapeId, true); // 使能选中的规则线
                    plugin.SelectVideoAnalyseShape(containerID, shapeId, bool); // 选中规则线
                });
            } else {
                plugin.CreateMainVideoAnalyseShape(containerID, 'PolygonEvent', shapeType, '', '', '');
            }
        });
        this.setState({regionIds, curIndex, rule});
    }
    deleteScene = () => {
        let {containerID, regionIds, rule, curIndex} = this.state;
        let {curScene} = this.props;
        plugin.DeleteVideoAnalyseShape(containerID, regionIds[curScene.Index]).then(() => {
            regionIds[curScene.Index] = 0;
            rule.Points = [];
            this.setState({regionIds, rule}, () => {
                this.drawScene(curScene, true);
            });
        });
    }
    clearAll = async () => {
        let {containerID, rule, regionIds, curIndex} = this.state;
        rule.Points = [];
        regionIds = [];
        if (containerID === 0) {
            if (plugin.isInStalled) {
                await plugin.CreateVideoAnalyseContainer().then(id => {
                    plugin.EnableVideoAnalyseContainer(id, true);
                    containerID = id;
                    this.setState({containerID});
                });
            }
        }
        plugin.DeleteAllVideoAnalyseShape(containerID);
        plugin.SetVideoAnalyseContainerTip(containerID, '');
        this.setState({curIndex: -1, rule, regionIds});
    }
    showRule = (bool, cur) => {
        let {containerID, regionIds} = this.state;
        plugin.ShowVideoAnalyseShape(containerID, regionIds[cur.Index], bool);
    }
    clearScene = (cur) => {
        let {containerID, regionIds} = this.state;
        plugin.DeleteAllVideoAnalyseShape(containerID);
        // plugin.DeleteVideoAnalyseShape(containerID, regionIds[cur.Index]);
    }
    enableShape = (bool, cur) => {
        let {containerID, regionIds} = this.state;
        plugin.SelectVideoAnalyseShape(containerID, regionIds[cur.Index], bool); // 使能选中的规则线
    }
    /**
     * 返回智能规则对象的配置，供外部使用
     */
    submit = () => {
        let {rule, containerID} = this.state;
        plugin.SetVideoAnalyseContainerTip(containerID, '');
        return {rule};
    }
    render () {
        return (
            <div></div>
        );
    }
}

export default LangConsumer(bindLifecycle(FocusExpertPlugin));